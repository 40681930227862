import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import { PrimaryButton, SecondaryButton } from "@metyis-ds/button";
import { ESidebarDirection, ESidebarModes } from "@metyis-ds/sidebar";
import { IContent } from "../contexts/ContentContext";
import { IEvent } from "../components/event/types";
import { useCheckWindowSize } from "@metyis-ds/hooks";
const mapRecruiteeJob = require("../helpers/helpers.ts").mapRecruiteeJob;
import { richText } from "./helpers";
import BreadCrumbs from "../components/breadcrumbs/BreadCrumbs";
import Form from "../components/form/Form";
import FullImage from "../components/fullImage/FullImage";
import Page from "../components/page/Page";
import QuoteList from "../components/quote/QuoteList";
import RedirectBanner from "../components/banner/RedirectBanner";
import RedirectBannerList from "../components/banner/RedirectBannerList";
import { Sidebar } from "../components/sidebar/Sidebar";
import Clock from "../assets/clock.svg";
import Close from "../assets/close.svg";
import Location from "../assets/location.svg";
import "./event.css";

export default ({
  pageContext
}: {
  pageContext: { content: IContent; event: IEvent; events: IEvent[] };
}) => {
  const { content, event } = pageContext;
  const {
    address,
    allowSignupLater,
    description,
    form,
    image,
    quoteList,
    recruiteeId,
    summary,
    title
  } = event;
  const location = useLocation();
  const isSmallScreen = useCheckWindowSize(1000);
  const [recruiteeEvent, setRecruiteeEvent] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showSignupLaterForm, setShowSignupLaterForm] = useState(false);
  const startDate = new Date(event.startDate);
  const endDate = new Date(event.endDate);
  const startDay = startDate.toLocaleDateString("en", {
    month: "long",
    day: "numeric",
    year: "numeric"
  });
  const endDay = endDate.toLocaleDateString("en", {
    month: "long",
    day: "numeric",
    year: "numeric"
  });
  const startTime = startDate.toLocaleTimeString("en", {
    minute: "numeric",
    hour: "numeric",
    hour12: false
  });
  const endTime = endDate.toLocaleTimeString("en", {
    minute: "numeric",
    hour: "numeric",
    hour12: false
  });
  const isSingleDay = startDay === endDay;
  const isPast = endDate < new Date();

  const mandatoryPolicies = {
    candidatesPolicy: true,
    marketingPolicy:
      recruiteeEvent &&
      recruiteeEvent.form?.formSections.some(section =>
        section?.questions?.some(question => question.type === "Legal")
      )
  };

  useEffect(() => {
    if (recruiteeId) {
      fetch(
        `https://metyisag.recruitee.com/api/offers/${recruiteeId.toLowerCase()}`
      )
        .then(res => res.json())
        .then(entry => {
          setRecruiteeEvent(mapRecruiteeJob(entry.offer));
        })
        .catch(err => console.log("An error occurred.", err));
    }
  }, []);

  const questionIds = recruiteeEvent?.form?.formSections
    ?.map(section =>
      section.questions
        .filter(question => question.id)
        .map(question =>
          question.type === "Checkbox" || question.type === "Legal"
            ? `_${question.id}`
            : question.id
        )
    )
    ?.flat()
    ?.join("-");

  return (
    <Page
      content={content}
      seo={{ description: summary.summary, image: image.file.url, title }}
    >
      <BreadCrumbs
        crumbsArray={[
          {
            name: "Events",
            redirectUrl: "/events"
          },
          {
            name: title
          }
        ]}
      />
      <section className="event">
        <div className="event-header">
          <div className="event-title-wrapper">
            <h1 className="event-title">{title}</h1>
            {!isPast && !isSmallScreen && (
              <div className="event-button-wrapper">
                {(!!form || !!recruiteeEvent) &&
                  recruiteeId !==
                    "event-metyis-in-house-day-2024-metyis-inhouse-day" && (
                    <>
                      <PrimaryButton onClick={() => setShowForm(true)}>
                        Apply now
                      </PrimaryButton>
                      {allowSignupLater && (
                        <SecondaryButton
                          onClick={() => setShowSignupLaterForm(true)}
                        >
                          Sign up later
                        </SecondaryButton>
                      )}
                    </>
                  )}
              </div>
            )}
          </div>
          <div className="event-details">
            <div className="event-details-item">
              <Clock className="event-icon" />
              <div className="event-details-item-inner">
                <span>
                  {isSingleDay ? startDay : `${startDay} - ${endDay}`}
                </span>
                <span className="event-time">{`${startTime} - ${endTime}`}</span>
              </div>
            </div>
            <div className="event-details-item">
              <Location className="event-icon" />
              <span>{address}</span>
            </div>
            {!isPast && isSmallScreen && (
              <div className="event-button-wrapper">
                {(!!form || !!recruiteeEvent) &&
                  recruiteeId !==
                    "event-metyis-in-house-day-2024-metyis-inhouse-day" && (
                    <>
                      <PrimaryButton onClick={() => setShowForm(true)}>
                        Apply now
                      </PrimaryButton>
                      {allowSignupLater && (
                        <SecondaryButton
                          onClick={() => setShowSignupLaterForm(true)}
                        >
                          Sign up later
                        </SecondaryButton>
                      )}
                    </>
                  )}
              </div>
            )}
          </div>
        </div>
        <FullImage image={image} />
        <div className="event-body">
          <div className="rich-text event-description">
            <div className="event-description-inner">
              {richText(description)}
            </div>
          </div>
        </div>
        {!!quoteList && <QuoteList {...quoteList} />}
        {!isPast &&
          (!!form || !!recruiteeEvent) &&
          recruiteeId !== "event-metyis-in-house-day-2024-metyis-inhouse-day" &&
          (allowSignupLater ? (
            <RedirectBannerList
              banners={[
                {
                  backgroundColor: "var(--color-blue-dark)",
                  textColor: "var(--color-white)",
                  title: "Apply to this event",
                  onClick: () => setShowForm(true)
                },
                {
                  backgroundColor: "var(--color-orange)",
                  textColor: "var(--color-white)",
                  title: "Sign up later",
                  onClick: () => setShowSignupLaterForm(true)
                }
              ]}
            />
          ) : (
            <RedirectBanner
              backgroundColor="var(--color-blue-dark)"
              textColor="var(--color-white)"
              title="Apply to this event"
              onClick={() => setShowForm(true)}
            />
          ))}
        {(!!form || recruiteeId) &&
          recruiteeId !==
            "event-metyis-in-house-day-2024-metyis-inhouse-day" && (
            <Sidebar
              className="sidebar"
              direction={
                isSmallScreen
                  ? ESidebarDirection.BOTTOM
                  : ESidebarDirection.RIGHT
              }
              mode={ESidebarModes.OVER}
              open={showForm}
              onClose={() => {
                setShowForm(false);
              }}
            >
              <div className="sidebar-header">
                <h3>Apply now</h3>
                <Close
                  className="sidebar-header-icon"
                  onClick={() => {
                    setShowForm(false);
                  }}
                />
              </div>
              <div className="sidebar-body">
                {recruiteeEvent || form ? (
                  <Form
                    {...(recruiteeEvent ? recruiteeEvent.form : form)}
                    formId={
                      recruiteeEvent
                        ? recruiteeId.toLowerCase() ===
                            "event-metyis-in-house-day-2024-metyis-inhouse-day" ||
                          "event-strategy-day-womens-edition-2024-metyis-strategy-day-womens-edition"
                          ? `${recruiteeEvent.form.formId}`
                          : `recruitee-${recruiteeEvent.form.formId}`
                        : `${form?.formId}-${title
                            .toLocaleLowerCase()
                            .replace(/[^a-zA-Z0-9 ]/g, "")
                            .trim()
                            .split(" ")
                            .join("-")}`
                    }
                    hiddenValue={questionIds}
                    mandatoryPolicies={
                      recruiteeEvent ? mandatoryPolicies : undefined
                    }
                    successPageTitle={title}
                    successPageText="Your application was submitted successfully."
                  />
                ) : (
                  <></>
                )}
              </div>
            </Sidebar>
          )}
        {allowSignupLater &&
          (!!form || recruiteeId) &&
          recruiteeId !==
            "event-metyis-in-house-day-2024-metyis-inhouse-day" && (
            <Sidebar
              className="sidebar"
              direction={
                isSmallScreen
                  ? ESidebarDirection.BOTTOM
                  : ESidebarDirection.RIGHT
              }
              mode={ESidebarModes.OVER}
              open={showSignupLaterForm}
              onClose={() => {
                setShowSignupLaterForm(false);
              }}
            >
              <div className="sidebar-header">
                <h3>Sign up later</h3>
                <Close
                  className="sidebar-header-icon"
                  onClick={() => {
                    setShowSignupLaterForm(false);
                  }}
                />
              </div>
              <div className="sidebar-body">
                <Form
                  formId="event-signup-later-form"
                  formSections={[
                    {
                      label: "Your contact",
                      questions: [
                        {
                          isMandatory: true,
                          label: "Email",
                          type: "Input"
                        }
                      ]
                    }
                  ]}
                  hiddenValue={`${title}__${location.href}`}
                  successPageTitle={title}
                  successPageText="Your application was submitted successfully."
                />
              </div>
            </Sidebar>
          )}
      </section>
    </Page>
  );
};
